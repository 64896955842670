import React from 'react';
import {Link} from 'gatsby'

import icon1 from '../../assets/images/cobranza/control.png'
import icon3 from '../../assets/images/cobranza/equipo.png'
import icon4 from '../../assets/images/cobranza/potencial.png'



const OurSolutions = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
             {/*            <img src={StarIcon} alt="image" />  */}
                        Beneficios
                    </span>
                    <h2>¡Por nuestro Software obtendrás!</h2>
                   {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon4} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Optimización
                                </Link>
                            </h3>
                            <p>Diseñe sus estrategias de cobros en función a sus necesidades, por estacionalidad, tipo de producto y/o tipo de cliente</p>

                           {/*  <Link to="/service-details" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon3} alt="banner" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Potencialice a su Equipo
                                </Link>
                            </h3>

                            <p>Asigne a sus equipos las cuentas más relevantes para que la cobranza sea más eficiente y efectiva para mayor efectividad</p>
                            
                           {/*  <Link to="/service-details" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon1} alt="banner" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Controle su Cobranza
                                </Link>
                            </h3> 

                            <p>Administre, gestione y obtenga reportes para una mayor precisión en la toma de decisiones </p>
                            
                           {/*  <Link to="/service-details" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;