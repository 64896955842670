import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import caseStudy1 from '../../assets/images/case-study/case-study1.jpg'
import caseStudy12 from '../../assets/images/case-study/case-study12.png'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const CaseStudy = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])
  

    return (
        <div className="case-study-area bg-fffbf5">
            {display ? <OwlCarousel 
                className="case-study-slides owl-carousel owl-theme"
                {...options}
            > 
                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                      {/*   <img src={starIcon} alt="case-study" />  */}
                                       MÓDULO DE CARGA
                                    </span>
                                    <h2>
                                Integración con orígenes de datos</h2>
                                <p>Sube tu base de datos de manera segura, eficiente y rápida desde la distancia de un clic.</p>

                    
          {/*                
                                <Link to="/projects/seq-gps" className="default-btn">
                                        <i className="flaticon-view"></i>
                                    
                                        <span></span>
                                    </Link>  */}  


                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <Link to="/cobranza" className="d-block">
                                        <img src={caseStudy12} alt="case-study" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                      {/*   <img src={starIcon} alt="case-study" />  */}
                                       administración 
                                    </span>
                                    <h2>Tablero interactivo</h2>
                                 <p>Sé esa persona con excelentes ideas. Las encuestas te dan percepciones prácticas y nuevos puntos de vista.</p>

                                 <ul>

                                    <li>
                                    Filtrado por variable
                                    </li>

                                    <li>
                                    Vista por cuentas asignadas
                                    </li>


                                    <li>
                                    Vista por cartera completa
                                    </li>

                                    <li>
                                    Vistas exportables a Excel
                                    </li>
                                 </ul>

                        
                         {/* <Link to="/projects/seq-gps" className="default-btn">
                                        <i className="flaticon-view"></i>
                                        Saber más 
                                        <span></span>
                                    </Link>  */}

                                
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <Link to="/cobranza" className="d-block">
                                        <img src={caseStudy1} alt="case-study" />
                                    </Link>
                                </div>
                            </div>


                            
                        </div>
                    </div>
                </div>
            </OwlCarousel> : ''}
        </div>
    )
}

export default CaseStudy