import React from 'react';

import StarIcon from '../../assets/images/star-icon.png'
import HowitsWork from '../../assets/images/how-its-work.png'
import OurMissionShape1 from '../../assets/images/our-mission/our-mission-shape1.png'
const HowItWork = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                {/* <img src={StarIcon} alt="image" />  */}
                             Reportería
                            </span>
                            <h2>Personalizables a los datos que necesites</h2>
                            <p> La creación y la distribución de informes sobre el desempeño empresarial para los responsables de la toma de decisiones importantes en una organización.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Analítica descriptiva</h3>
                                    {/* <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p> */}
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Gestiones por hora</h3>
                                    {/* <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p> */}
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Productividad diaria</h3>
                                    {/* <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p> */}
                                </div>

                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>Reporte de gestiones</h3>
                                    {/* <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p> */}
                                </div>

                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>Seguimiento de proyección</h3>
                                    {/* <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

             {/*        <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowitsWork} alt="image" />
                        </div>
                    </div> */}


                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={HowitsWork} alt="image" />
                            <div className="shape">
                                <img src={OurMissionShape1} alt="image" />
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </section>
    )
}

export default HowItWork;