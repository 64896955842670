import React from 'react';
import {Link} from 'gatsby'

import AboutImage from '../../assets/images/about/about-img10.png'
import AboutShape from '../../assets/images/about/about-shape1.png'
import StarIcon from '../../assets/images/star-icon.png'
import OurMissionShape from '../../assets/images/our-mission/our-mission-shape2.png'
import CircleShape from '../../assets/images/shape/circle-shape1.png'

const AboutArea = () => {
    return (
        <section className="about-area pb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={AboutImage} alt="About Image" />
                            <div className="shape">
                                <img src={AboutShape} alt="About Shape" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    {/* <img src={StarIcon} alt="Star Icon" />  */}
                                    Funciones
                                </span>
                                <h2>Todo lo que necesitas de una forma óptima, precisa y fiable</h2>
                                <p>Para del crecimiento de una empresa es que sea capaz de gestionar su proceso con ayuda de la digitalización.</p>

                                <ul className="about-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Historial
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Gestiones
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                       Datos de clientes
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                       Control de pagos
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                       Petición de rutas
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Refinanciamiento
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>



    


                                </ul>
                               {/*  <p>Y por más funciones que ayuden al crecimiento de tu empresa.</p> */}
         
              {/*                   
                                <Link to="/about-us-two" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Más acerca de nosotros
                                    <span></span>
                                </Link> */}



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={CircleShape} alt="Circle Shape" />
            </div>
        </section>
    )
}

export default AboutArea;